import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
    static values = {
        placeholderText: String,
        allowDeselect: Boolean
    }

    connect() {
        this.slimSelect = new SlimSelect({
            select: this.element,
            settings: {
                placeholderText: this.placeholderTextValue,
                allowDeselect: this.allowDeselectValue
            }
        })
    }

    disconnect() {
        this.slimSelect.destroy();
    }
}
